import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "making-your-components-more-flexible-with-render-props"
    }}>{`Making your components more flexible with render props`}</h2>
    <p><em parentName="p">{`Render props are a great technique for making your components more flexible. `}<a parentName="em" {...{
          "href": "/building-blocks/5"
        }}>{`React Hooks`}</a>{` make them less critical but still a very useful technique.`}</em></p>
    <Vimeo id={424603352} mdxType="Vimeo" />
    <p>{`Our scatterplot doesn't look quite as nice as the earlier screenshot. Regular
SVG circles with no styling just can't match up.`}</p>
    <p>{`What if we wanted to render beautiful circles? Or stars? Or maybe something
else entirely?`}</p>
    <p>{`We can use render props to give users of our scatterplot component the power to
define how they want datapoints to render. 😱`}</p>
    <p>{`Think of it as a sort of inversion of control. Another common buzzword are
"slots", or renderless components. The idea is that one of our props accepts a
React component.`}</p>
    <p>{`We then use that prop to render our datapoints.`}</p>
    <p>{`It looks a little like this 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Scatterplot
  x={10} y={10}
  data={data}
  datapoint={(props) => <Datapoint {...props} />}
>
`}</code></pre>
    <p>{`What's more, we can add interactions and other useful stuff to our
`}<inlineCode parentName="p">{`<Datapoint>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Scatterplot>`}</inlineCode>{` doesn't have to know anything about it. All
the scatterplot cares about is rendering two axes and a bunch of datapoints.`}</p>
    <p>{`Let's use the render prop approach to make our scatterplot more reusable.`}</p>
    <p>{`Steps 👇`}</p>
    <ul>
      <li parentName="ul">{`pass in a render prop`}</li>
      <li parentName="ul">{`use it to render datapoints`}</li>
      <li parentName="ul">{`make datapoint component look nice`}</li>
    </ul>
    <p>{`You can see `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/j73xlyr8v5"
      }}>{`my solution on CodeSandbox`}</a>{`.
I recommend you follow along with your existing code.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/j73xlyr8v5",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h3 {...{
      "id": "pass-in-a-render-prop"
    }}>{`Pass in a render prop`}</h3>
    <p>{`React components are Just JavaScript. Either a JSX function call or a function
that returns some JSX.`}</p>
    <p>{`That means we can pass them into components via props. Let's do that in App.js`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// App.js

import Datapoint from "./Datapoint"

//..
;<svg width="800" height="800" onClick={this.onClick}>
  <Scatterplot
    x={50}
    y={50}
    width={width}
    height={height}
    data={data}
    datapoint={({ x, y }) => <Datapoint x={x} y={y} />}
  />
</svg>
`}</code></pre>
    <p>{`For extra flexibility and readability we're wrapping our `}<inlineCode parentName="p">{`<Datapoint>`}</inlineCode>{`
component in another function that accepts `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` coordinates. This is a
common pattern you'll see with render props 👉 it gives you the
ability to pass in props from both the rendering component and the component
that's setting the render prop.`}</p>
    <p>{`Say we wanted Datapoint to know something about our App `}<em parentName="p">{`and`}</em>{` our Scatterplot.
The scatterplot calls this function with coordinates. We pass those into
`}<inlineCode parentName="p">{`<Datapoint>`}</inlineCode>{`. And because the method is defined inside App, we could pass-in
anything that's defined in the App. Like perhaps `}<inlineCode parentName="p">{`data`}</inlineCode>{`.`}</p>
    <p>{`Your code will start throwing an error now. Datapoint isn't defined. Don't
worry, it's coming soon.`}</p>
    <h3 {...{
      "id": "use-render-prop-to-render-datapoints"
    }}>{`Use render prop to render datapoints`}</h3>
    <p>{`To use our new `}<inlineCode parentName="p">{`datapoint`}</inlineCode>{` render prop, we have to change how we render the
scatterplot. Instead of returning a `}<inlineCode parentName="p">{`<circle>`}</inlineCode>{` for each iteration of the
dataset, we're calling a function passed in from props.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Scatterplot.js
  render() {
    const { x, y, data, height, datapoint } = this.props,
      { yScale, xScale } = this.state;

    return (
      <g transform={\`translate(\${x}, \${y})\`}>
        {data.map(([x, y]) => datapoint({
            x: xScale(:satisfied:,
            y: yScale(y)
        }))}
`}</code></pre>
    <p>{`We take the `}<inlineCode parentName="p">{`datapoint`}</inlineCode>{` function from props and call it in `}<inlineCode parentName="p">{`data.map`}</inlineCode>{` making
sure to pass in `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` as an object. Calling functions with objects like
this is a common JavaScript pattern to fake named arguments.`}</p>
    <h3 {...{
      "id": "make-datapoint-component-look-nice"
    }}>{`Make datapoint component look nice`}</h3>
    <p>{`We've got all the rendering, now all we need is the `}<inlineCode parentName="p">{`<Datapoint`}</inlineCode>{` component
itself. That goes in a new `}<inlineCode parentName="p">{`Datapoint.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from "react"
import styled from "styled-components"

const Circle = styled.circle\`
  fill: steelblue;
  fill-opacity: 0.7;
  stroke: steelblue;
  stroke-width: 1.5px;
\`

class Datapoint extends React.Component {
  render() {
    const { x, y } = this.props

    return <Circle cx={x} cy={y} r={3} />
  }
}

export default Datapoint
`}</code></pre>
    <p>{`I'm using styled-components to define the CSS for my Datapoint. You can use
whatever you prefer. I like styled components because they're a good balance
between CSS-in-JS and normal CSS syntax.`}</p>
    <p>{`The component itself renders a styled circle using props for positioning and a
radius of 3 pixels.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/scatterplot-finished.png",
        "alt": "Finished scatterplot"
      }}></img></p>
    <p>{`For an extra challenge, try rendering circle radius from state and changing
datapoint size on mouse over. Make the scatterplot interactive.`}</p>
    <Vimeo id={424601672} mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      